/* SimpleVisitCounter.css */
.simple-visit-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 5px 10px;
    margin: 5px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 0.85rem;
    max-width: fit-content;
  }
  
  .visit-item {
    display: flex;
    align-items: center;
    margin: 0 5px;
    white-space: nowrap;
  }
  
  .visit-icon {
    color: #269830;
    margin-right: 4px;
    font-size: 0.9em;
  }
  
  .visit-label {
    font-weight: 500;
    color: #555;
    margin-right: 3px;
  }
  
  .visit-value {
    font-weight: 700;
    color: #269830;
  }
  
  .simple-visit-counter-loading {
    height: 25px;
    width: 120px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .simple-visit-counter-loading:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid rgba(38, 152, 48, 0.3);
    border-radius: 50%;
    border-top-color: #269830;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* 화면이 작은 경우 세로 레이아웃으로 변경 */
  @media (max-width: 480px) {
    .simple-visit-counter {
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 8px;
    }
    
    .visit-item {
      margin: 2px 0;
    }
  }