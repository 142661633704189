/* Manage.css */

.Manage {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Noto Sans KR', sans-serif;
}

.Manage-header {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 30px;
  color: #333;
}

.Manage-header h1 {
  font-size: 2rem;
  font-weight: 700;
  border-bottom: 3px solid #269830;
  padding-bottom: 10px;
  display: inline-block;
}

.ManageContainer {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 탭 버튼 스타일 */
.tab-buttons {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.tab-buttons button {
  flex: 1;
  padding: 15px 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #666;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-buttons button.active {
  background-color: #269830;
  color: white;
  border-bottom: 3px solid #1a6e23;
}

.tab-buttons button.inactive {
  background-color: #f5f5f5;
}

.tab-buttons button.inactive:hover {
  background-color: #e0e0e0;
}

.tab-buttons button svg {
  margin-right: 8px;
}

/* 접근 제한 메시지 */
.access-denied {
  text-align: center;
  padding: 50px 20px;
  background-color: #ffebee;
  border-radius: 8px;
  margin-top: 20px;
}

.access-denied h2 {
  color: #b71c1c;
  margin-bottom: 15px;
}

.access-denied p {
  color: #333;
  font-size: 16px;
}

/* 콘텐츠 영역 */
.manage-content {
  padding: 20px;
}

/* 방문자 수 탭 */
.visitcount {
  display: flex;
  flex-direction: column;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.visit-count-button {
  background-color: #269830;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.visit-count-button:hover {
  background-color: #1a6e23;
}

.visit-count-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

.visit-count-button svg {
  margin-right: 8px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

table th {
  background-color: #f0f0f0;
  font-weight: 600;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

table th:hover {
  background-color: #e0e0e0;
}

table td {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

.record-table tr.selected {
  background-color: #e8f5e9 !important;
  border-left: 3px solid #269830;
}

.url-link {
  color: #1a6e23;
  text-decoration: none;
}

.url-link:hover {
  text-decoration: underline;
}

/* 데이터 관리 탭 */
.datamanage {
  display: flex;
  flex-direction: column;
}

.table-selection-container {
  margin-bottom: 20px;
}

.table-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.record-control {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}

.action-button svg {
  margin-right: 5px;
}

.action-button.add {
  background-color: #269830;
  color: white;
}

.action-button.add:hover {
  background-color: #1a6e23;
}

.action-button.edit {
  background-color: #2196f3;
  color: white;
}

.action-button.edit:hover {
  background-color: #0d8bf2;
}

.action-button.delete {
  background-color: #f44336;
  color: white;
}

.action-button.delete:hover {
  background-color: #d32f2f;
}

.action-button.save {
  background-color: #269830;
  color: white;
}

.action-button.cancel {
  background-color: #757575;
  color: white;
  margin-left: 10px;
}

.action-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.search-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
}

.search-icon {
  color: #666;
  margin-right: 8px;
}

.search-input {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  width: 200px;
}

.record-list-container {
  margin-top: 20px;
}

.record-list-container h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  border-left: 4px solid #269830;
  padding-left: 10px;
}

.form-container {
  margin-top: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #ddd;
}

.form-container h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #269830;
  padding-bottom: 10px;
}

.field-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.input-group .required {
  color: #f44336;
  margin-left: 3px;
}

.input-group input,
.input-group select,
.input-group textarea {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.input-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

/* 로딩 및 상태 메시지 */
.status-message {
  background-color: #f8f9fa;
  border-left: 4px solid #269830;
  padding: 12px 15px;
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

.status-message.loading {
  background-color: #e8f5e9;
  border-left-color: #269830;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #269830;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data-message {
  text-align: center;
  padding: 40px 20px;
  color: #666;
  font-size: 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 10px;
}

/* 페이지네이션 컨트롤 */
.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  flex-wrap: wrap;
  gap: 10px;
}

.records-per-page {
  display: flex;
  align-items: center;
  gap: 10px;
}

.records-per-page label {
  font-size: 14px;
  color: #555;
}

.records-per-page input {
  width: 60px;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}

.record-stats {
  font-size: 14px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 5px;
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f5f5f5;
  border-color: #bbb;
  color: #333;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  gap: 5px;
  margin: 0 5px;
}

.pagination-number {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  color: #666;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.pagination-number:hover:not(.active) {
  background-color: #f5f5f5;
  border-color: #bbb;
  color: #333;
}

.pagination-number.active {
  background-color: #269830;
  border-color: #269830;
  color: white;
  cursor: default;
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .Manage {
    padding: 10px;
  }
  
  .tab-buttons button {
    padding: 10px;
    font-size: 14px;
  }
  
  .field-inputs {
    grid-template-columns: 1fr;
  }
  
  .record-control {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-container {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
  
  .search-input {
    width: 100%;
  }
  
  .action-button {
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;
  }

  .pagination-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .records-per-page {
    width: 100%;
    justify-content: space-between;
  }
  
  .record-stats {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

/* Manage.css에 추가할 스타일 */

/* 정렬 컨트롤 스타일 */
.sort-controls, .timezone-controls {
  display: flex;
  align-items: center;
  margin-right: 20px; 
}

.sort-controls label, .timezone-controls label {
  margin-right: 8px;
  white-space: nowrap;
}

.sort-controls select, .timezone-controls select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.timezone-icon {
  margin-right: 5px;
  color: #555;
}

.sort-direction-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  border-radius: 4px;
  color:#333;
  background-color: #f5f5f5;
}

.sort-direction-button:hover {
  background-color: #e0e0e0;
}

/* 테이블 헤더 정렬 표시 스타일 */
.record-table th {
  cursor: pointer;
  padding: 10px;
  position: relative;
  user-select: none;
}

.record-table th.sorted {
  background-color: #f0f8ff;
}

.record-table th .sort-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.record-table th:hover {
  background-color: #f0f0f0;
}

/* 페이지네이션 컨트롤 스타일 향상 */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .pagination-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sort-controls, .records-per-page, .record-stats {
    margin-bottom: 10px;
  }
}