body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* 가로 스크롤 방지 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 전역 CSS 변수 - 색상, 스타일 등의 일관성을 위해 */
:root {
  --color-white: #ffffff;
  --color-black: #333333;
  --color-gray: #666666;
  --color-lightgray: #dddddd;
  --color-green: #4CAF50;
  --color-blue: #2196F3;
  --color-orange: #FF9800;
  --color-red: #f44336;
  
  /* 폰트 크기 */
  --font-size-small: 12px;
  --font-size-default: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;
  --font-size-h1: 24px;
  --font-size-h2: 20px;
  
  /* 여백 및 간격 */
  --spacing-small: 5px;
  --spacing-default: 10px;
  --spacing-medium: 15px;
  --spacing-large: 20px;
  
  /* 애니메이션 시간 */
  --transition-fast: 0.2s;
  --transition-default: 0.3s;
  --transition-slow: 0.5s;
}

/* 접근성 포커스 스타일 */
:focus {
  outline: 2px solid var(--color-blue);
  outline-offset: 2px;
}

/* 모바일 터치 최적화 */
@media (max-width: 768px) {
  html {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }
  
  button, a, input, textarea, select {
    min-height: 44px; /* 터치 타겟 최소 크기 */
  }
}