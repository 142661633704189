/* components/common/Pagination.css */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 5px;
  }
  
  .pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: white;
    color: #666;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
    overflow: visible; /* 오버플로우 문제 방지 */
  }
  
  .pagination-button svg {
    width: 16px;
    height: 16px;
    fill: currentColor; /* SVG 아이콘의 색상이 부모 요소의 color 값을 따르도록 설정 */
    display: block; /* 인라인 요소로 인한 여백 문제 방지 */
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #f5f5f5;
    border-color: #bbbbbb;
    color: #333;
  }
  
  .pagination-button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #f9f9f9;
  }
  
  .page-numbers {
    display: flex;
    gap: 5px;
  }
  
  .pagination-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: white;
    color: #666;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .pagination-number:hover:not(.active) {
    background-color: #f5f5f5;
    border-color: #bbbbbb;
    color: #333;
  }
  
  .pagination-number.active {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
    font-weight: 600;
    cursor: default;
    box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);
  }
  
  /* 반응형 디자인 */
  @media screen and (max-width: 480px) {
    .pagination {
      margin: 15px 0;
    }
    
    .pagination-button,
    .pagination-number {
      width: 36px;
      height: 36px;
      font-size: 13px;
    }
    
    .pagination-button svg {
      width: 14px;
      height: 14px;
    }
    
    .page-numbers {
      gap: 3px; 
    }
  }