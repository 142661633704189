/* PageStatsDashboard.css */
.page-stats-dashboard {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .page-title h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .date-range {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
  }
  
  .stats-summary {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .stats-card {
    text-align: center;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    width: 30%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s;
  }
  
  .stats-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .stats-icon {
    font-size: 1.8rem;
    color: #269830;
    margin-bottom: 10px;
  }
  
  .stats-number {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
  }
  
  .stats-label {
    font-size: 0.9rem;
    color: #666;
  }
  
  .stats-detail-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .stats-detail-card {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .stats-detail-card h4 {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .stats-detail-card h4 svg {
    margin-right: 5px;
    color: #269830;
  }
  
  /* 일별 방문 차트 스타일 */
  .daily-chart {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 200px;
    padding-top: 20px;
  }
  
  .chart-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 14 - 4px);
    min-width: 20px;
  }
  
  .chart-bar {
    background-color: #269830;
    width: 100%;
    border-radius: 3px 3px 0 0;
    position: relative;
    min-height: 5px;
    transition: height 0.3s;
  }
  
  .chart-bar:hover {
    background-color: #1e7d26;
  }
  
  .bar-value {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7rem;
    color: #555;
    font-weight: 500;
    padding: 2px 0;
    white-space: nowrap;
  }
  
  .chart-label {
    font-size: 0.7rem;
    color: #777;
    margin-top: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  /* 디바이스 및 국가별 통계 스타일 */
  .stats-pie-chart {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .pie-segment {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .segment-color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .segment-label {
    flex: 1;
    font-size: 0.85rem;
    color: #555;
  }
  
  .segment-value {
    font-size: 0.85rem;
    color: #777;
    font-weight: 500;
  }
  
  .stats-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .stats-list-item {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  
  .country-flag {
    font-size: 1.2rem;
    margin-right: 8px;
  }
  
  .country-name {
    flex: 1;
    font-size: 0.85rem;
    color: #555;
  }
  
  .country-count {
    font-size: 0.85rem;
    font-weight: 500;
    color: #333;
    margin-right: 5px;
  }
  
  .country-percent {
    font-size: 0.8rem;
    color: #777;
  }
  
  /* 데이터 없음 메시지 */
  .no-stats-message {
    text-align: center;
    padding: 30px;
    color: #777;
    font-style: italic;
    background-color: #f8f9fa;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  /* 반응형 레이아웃 */
  @media (max-width: 768px) {
    .stats-summary {
      flex-direction: column;
      align-items: center;
    }
    
    .stats-card {
      width: 80%;
      margin-bottom: 15px;
    }
    
    .daily-chart {
      height: 150px;
    }
  }