
.music-player-container {
  background: linear-gradient(135deg, rgba(222, 164, 30, 0.9), rgba(160, 82, 45, 0.85), rgba(205, 133, 63, 0.8));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  max-width: 350px;
}

.waveform {
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: rgba(30, 30, 30, 0.6);
}

.music-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.control-button {
  background-color: #269830;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 10px;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: #1c7425;
  color: white;
}

.playlist-button {
  background-color: #1a6b23;
  color: white;
}

.track-info {
  text-align: center;
  margin-top: 5px;
}

.playlist-container {
  margin-top: 10px;
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 4px;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
}

.playlist-container h4 {
  color: rgb(212, 37, 239);
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.playlist {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.playlist li {
  color: #e1dfe9;
  padding: 1px 8px;
  margin-bottom: 1px;
  border-radius: 3px;
  border-color:#1a6b23;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.playlist li:hover {
  background-color: rgba(70, 70, 70, 0.8);
  color: white;
}

.playlist li.active-track {
  background-color: #52c75c;
  color: white;
}