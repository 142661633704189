/* VisitStatsTab.css */
.visitstats {
    width: 100%;
    margin-top: 20px;
  }
  
  .filter-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
  }
  
  .period-buttons {
    display: flex;
    gap: 10px;
  }
  
  .period-buttons button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    color: #555;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .period-buttons button:hover {
    border-color: #269830;
    color: #269830;
  }
  
  .period-buttons button.active {
    background-color: #269830;
    color: white;
    border-color: #269830;
  }
  
  .period-buttons button svg {
    margin-right: 5px;
  }
  
  .filter-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .page-filter {
    position: relative;
  }
  
  .page-select {
    padding: 8px 32px 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    appearance: none;
    min-width: 150px;
  }
  
  .page-filter::after {
    content: "▼";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 12px;
    color: #666;
  }
  
  .search-box {
    position: relative;
  }
  
  .search-input {
    padding: 8px 12px 8px 36px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 200px;
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
  
  .refresh-button,
  .export-toggle-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    color: #555;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .refresh-button:hover,
  .export-toggle-button:hover {
    border-color: #269830;
    color: #269830;
  }
  
  .refresh-button svg,
  .export-toggle-button svg {
    margin-right: 5px;
  }
  
  .export-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #eee;
  }
  
  .stats-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .stats-count {
    font-size: 0.9rem;
    color: #666;
  }
  
  .table-container {
    width: 100%;
    overflow-x: auto;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .visit-stats-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  }
  
  .visit-stats-table th,
  .visit-stats-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .visit-stats-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #444;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .visit-stats-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .view-details-button {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #269830;
    background-color: transparent;
    color: #269830;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.85em;
  }
  
  .view-details-button:hover {
    background-color: #269830;
    color: white;
  }
  
  .no-data-message {
    text-align: center;
    padding: 30px;
    color: #777;
    font-style: italic;
  }
  
  /* 반응형 디자인 */
  @media (max-width: 768px) {
    .filter-bar {
      flex-direction: column;
      align-items: stretch;
    }
    
    .filter-controls {
      flex-wrap: wrap;
    }
    
    .search-input {
      width: 100%;
    }
  }