/* VisitStatsExport.css */
.visit-stats-export {
    margin: 15px 0;
  }
  
  .export-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .export-format-select {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 0.9rem;
    color: #444;
    min-width: 100px;
  }
  
  .export-format-select:focus {
    outline: none;
    border-color: #269830;
    box-shadow: 0 0 0 2px rgba(38, 152, 48, 0.2);
  }
  
  .export-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #269830;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .export-button:hover {
    background-color: #1e7d26;
  }
  
  .export-button:disabled {
    background-color: #8cc691;
    cursor: not-allowed;
  }
  
  .export-button svg {
    font-size: 1rem;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* 모바일 대응 */
  @media (max-width: 480px) {
    .export-controls {
      flex-direction: column;
      align-items: stretch;
    }
    
    .export-format-select,
    .export-button {
      width: 100%;
    }
  }